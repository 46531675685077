<template>
  <div class="col-12 g">
    <div class="card">
        <div class="card-header">
            <h5>
                اختيار المخططات <input type="text" placeholder="ابحث هنا..." style="border: solid 1px #eee; padding: 10px; border-radius: 5px;" v-model="q" @change="search()" @keyup="search()"> <img v-if="loading" :src="require('@/assets/images/loading.svg')" style="width: 30px">
            </h5>
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            المخطط
                        </th>
                        <th>
                            متاح للحجز
                        </th>
                        <th>
                            الكل
                        </th>
                        <th>
                            المتاح
                        </th>
                        <th>
                            ارسال تنبيه
                        </th>
                        <th>
                            حجز تلقائي
                        </th>
                        <th>
                            حجز الآن
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="project in projects" :key="project._id">
                            <td>
                                ({{ project.item_id }})
                                {{ project.name }}
                            </td>
                            <td>
                                <span v-if="project.bookable" class="text-success">
                                    <i class="fa fa-check-square"></i>
                                    متاح للحجز
                                </span>
                                <span v-if="!project.bookable" class="text-danger">
                                    <i class="fa fa-times"></i>
                                    غير
                                    متاح
                                </span>
                            </td>
                            <td>
                                {{ project.all }}
                            </td>
                            <td>
                                {{ project.avilable }}
                            </td>
                            <td>
                                <b-form-checkbox
                                    :unchecked-value="false"
                                    name="check-button"
                                    switch
                                    :value="true"
                                    v-model="project.noti"
                                    @change="changeInput(project.item_id, 'noti', project.noti)"
                                >
                                </b-form-checkbox>
                            </td>
                            <td>
                                <b-form-checkbox
                                    :unchecked-value="false"
                                    name="check-button"
                                    switch
                                    :value="true"
                                    v-model="project.book"
                                    @change="changeInput(project.item_id, 'book', project.book)"
                                >
                                </b-form-checkbox>
                            </td>
                            <td>
                                <button class="btn btn-primary" v-if="!project.booked" @click="bookMe(project)">
                                    حجز الآن
                                </button>
                                <button class="btn btn-success" disabled v-if="project.booked">
                                    <i class="fa fa-check"></i>
                                    تم الحجز
                                </button>
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BOverlay, BModal, VBModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            loading: false,
            user: JSON.parse(localStorage.getItem("user")),
            original: [],
            projects: [], 
            q: null
        }
    },
    created(){
        var g = this;
        g.getProjects();
    },
    methods: {
        getProjects(){
            var g = this;
            g.loading = true;
            $.post(api + '/user/projects/all', {
                jwt: g.user.jwt
            }).then(function(r){
                g.projects = r.response
                g.original = r.response
                g.loading = false;
            })
        },
        search(){
            var g = this;
            var arr = [];
            g.original.forEach(function(a){
                if(a.name.includes(g.q)){
                    arr.push(a)
                }
            })
            g.projects = arr;
        },
        changeInput(id, input, value){
            var g = this;
            $.post(api + '/user/projects/all', {
                jwt: g.user.jwt
            }).then(function(r){
                $.post(api + '/user/projects/edit', {
                    jwt: g.user.jwt,
                    input: input,
                    id: id,
                    value: value
                }).then(function(r){
                    g.getProjects()
                }).fail(function(){
                    alert("حدث خطأ")
                    g.getProjects()
                })
            })
        },
        bookMe(p){
            var g = this;
            $.post(api + '/user/projects/book-now', {
                jwt: g.user.jwt,
                id: p.item_id
            }).then(function(r){
                alert("تم بنجاح")
            })
        }
    }
}
</script>

<style>

</style>